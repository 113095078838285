import React from "react";
import "./WelcomeScreen.css";
import { Link } from "react-router-dom";

const WelcomeScreen = () => {

  function GetGyro(){
     document.getElementById("mainscreenContainer").style.display="block"
    document.getElementById("welComeContainer").style.display="none"

    window.AddHotPoints();
       
    if(isIOS()){
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
          DeviceOrientationEvent.requestPermission()
            .then(function (permissionState) {
              if (permissionState === "granted") {
                
              }
            })
            .catch(function (error) {
              //console.error(error);
              alert(error);
            });
        } else {
          // Device orientation API not supported
          console.error("Device orientation not supported");
        }
  }

     
  }
  function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
  return (
    <div className="Main-Screen1">
      {/* <div className="Smoke1"></div> */}
      
      <div></div>
      <div className="Logo1 bg2"></div>
      <div className="Heading1">
        Customize your calm with the<br/> SteamScape™ Shower Series.
        <div className="Button">
        {/* <Link to="/mainscreen"> */}
          <button className="startbtn" onClick={GetGyro}>START YOUR EXPERIENCE</button>
        {/* </Link> */}
        </div>
      </div>
     

    </div>
  );
};

export default WelcomeScreen;

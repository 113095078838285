import React, {useState, useEffect} from "react";
import "./Generator.css";
import { Link, useNavigate } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import Genrator1 from "../../Assets/Genrator1.webp";
import Genrator2 from "../../Assets/Genrator2.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";

const Generator = () => {
  const clients = [
    {
      id: 1,
      img: Genrator1,
      review: "GENERATOR",
      text: "SteamScape™ Generator",
      generatorparagraph1:
      "SteamScape™ Generator available in<br/> multiple sizes to accommodate a variety<br/> of shower spaces and a compact design<br/> for convenient and easily accessible<br/> installation locations.",
      generatorparagraph2:
      "SteamScape™ Generator features<br/> Proportional Steam Technology to<br/> regulate steam environment without<br/> temperature spikes.",
    },
    {
      id: 2,
      img: Genrator2,
      review: "GENERATOR",
      text: "Pan & AUTO DRAIN",
      generatorparagraph1:
        "Generator Pan features elevated support<br/> for generator and solid-brass plumbing<br/> connection",
      generatorparagraph2:
        "Auto Drain will open automatically to<br/> purge the system after water in<br/> generator cools.",
    },
  ];
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
      setTimeout(() => {
        document.getElementById("gencard").style.opacity = 1;
      }, 100);
  }, []); 
  useEffect(() => {
    if (shouldRedirect) {
      setTimeout(() => {
        // navigate('/mainscreen');
        document.getElementById("GeneratorCardHome").style.display="none";
        document.getElementById("mainscreenContainer").style.display="block";
      }, 400);
      
    }
  }, [shouldRedirect, navigate]);
  const closeCardContainer = ()=>{
    document.getElementById("gencard").style.opacity = 1;
    document.getElementById("GeneratorCardHome").style.display="none";
    document.getElementById("mainscreenContainer").style.display="block";
    setShouldRedirect(true);
    console.log("close");
  }
  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <div className="Genratorwrapper" id="gencard">
      <Swiper
        cssMode={true}
        navigation={true}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="mySwiper"
      >
        {clients.map((clients, id) => {
          return (
            <SwiperSlide key={id}>
              <div className="Cardcontainer">
                <div className="Cardfixedcontainer">
                  <div className="Cardibutton">
                    <button className="btnlayers1">i</button>
                  </div>
                  <div className="Cardimagediv">
                    <img src={clients.img} alt="" />{" "}
                  </div>
                  <div className="Cardmaintext" dangerouslySetInnerHTML={createMarkup(clients.review)}></div>
                  <div className="Cardunilateral" dangerouslySetInnerHTML={createMarkup(clients.text)}></div>
                </div>

                <div className="Generatorpara">
                  <p dangerouslySetInnerHTML={createMarkup(clients.generatorparagraph1)}></p>
                  <p className="steamheadpara3" dangerouslySetInnerHTML={createMarkup(clients.generatorparagraph2)}></p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="crossBtnContainer">
        <button className="SteamButton1" onClick={closeCardContainer} >
          <img src={Cross} alt="" />
        </button>
    </div>
    </div>
  );
};

export default Generator;

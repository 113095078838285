import React, {useEffect, useState} from "react";
import "./Digitalinterface.css";
import { Link, useNavigate } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import dihomescreen from "../../Assets/di_homescreen.png";
import diuserprofiles from "../../Assets/di_userprofiles.png";
import disteamsettings from '../../Assets/di_steam.png';
import disteam from "../../Assets/di_steamsettings.png";
import diaudiosettings from "../../Assets/di_audiosettings.png";
import dipreset from '../../Assets/di_preset.png';


import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";

const Digitalinterface = () => {
  const clients = [
    {
      img: dihomescreen,
      review: "DIGITAL INTERFACE",
      text: "HOME SCREEN",
      Diparagraph1:
        "The touchscreen digital interface is installed<br/> inside the shower area and gives you total<br/> control of your steam session while in the<br/> shower, or through a smartphone via the<br/> DFC@Home app while in your home. Start<br/> your steam session from your phone or with<br/> voice activation through Amazon Alexa or<br/> Google Home virtual assistants. ",
      Diparagraph2:
        "Add our optional Audio Speaker System to<br/> listen to music during a steam session or<br/> shower.",
      widtHeading: {
        fontSize: '4.7cqh',
      },
      widthPara: {
        fontSize: '1.75cqh',
      }
    },
    {
      img: diuserprofiles,
      review: "DIGITAL INTERFACE",
      text: "USER PROFILES",
      Diparagraph1:
        "The digital interface allows for three<br/> separate user profiles plus a guest profile.<br/> Customize through the DFC@Home app<br/> for your whole family.",
        widtHeading: {
          fontSize: '4.7cqh',
        },
        widthPara: {
          fontSize: '1.75cqh',
        }
    },
    {
      img: dipreset,
      review: "DIGITAL INTERFACE",
      text: "Presets/Scenes",
      Diparagraph1:
        "Also customize up to 4 presets per profile. Set<br/> personalized steam temperature, duration,<br/> and audio preferences, so your settings are<br/> exactly to your liking for all of life’s moments.",
      Diparagraph2:
        "* Not programmable for the guest profile",
        widtHeading: {
          fontSize: '4.7cqh',
        },
        widthPara: {
          fontSize: '1.75cqh',
        }
    },
    {
      img: disteam,
      review: "DIGITAL INTERFACE",
      text: "Steam Running",
      Diparagraph1:
        "Select a Preset and relax, steam will start<br/> dispersing in about 5 minutes.  Easily change<br/> steam or audio settings throughout the<br/> steam session through the interface.",
      Diparagraph2:
        "Patented dual sensor technology delivers<br/> accurate readings to regulate the steam<br/> environment.",
        widtHeading: {
          fontSize: '4.7cqh',
        },
        widthPara: {
          fontSize: '1.75cqh',
        }
    },
    {
      img: disteamsettings,
      review: "DIGITAL INTERFACE",
      text: "STEAM Settings",
      Diparagraph1:
        "The digital control turns steam on/off,<br/> adjusts the temperature and sets<br/> the steam shower duration.",
      Diparagraph2:
        "Patented Dual Sensor Technology<br/> delivers accurate readings to regulate the<br/> steam environment.",
        widtHeading: {
          fontSize: '4.7cqh',
        },
        widthPara: {
          fontSize: '1.75cqh',
        }
    },
    {
      img: diaudiosettings,
      review: "DIGITAL INTERFACE",
      text: "Audio Settings",
      Diparagraph1:
        "Play soothing music or listen to your<br/> favorite podcast. The digital interface<br/> connects to your device via Bluetooth or<br/> with an audio jack. Skip through your<br/> playlist, pause and play audio. Adjust bass,<br/> treble, balance and volume. ",
        widtHeading: {
          fontSize: '4.7cqh',
        },
        widthPara: {
          fontSize: '1.75cqh',
        }
      },
  ];
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
      
    // if (steamContainerRef.current) {
      // console.log("Element is now in the DOM!", steamContainerRef.current);
      setTimeout(() => {
        document.getElementById("Card").style.opacity = 1;
      }, 100);
      
    // }
}, []);
useEffect(() => {
  if (shouldRedirect) {
    setTimeout(() => {
      // navigate('/mainscreen');
      document.getElementById("DigitalInterfaceCardHome").style.display="none";
      document.getElementById("mainscreenContainer").style.display="block";
    }, 400);
    
  }
}, [shouldRedirect, navigate]);
  const closeCardContainer = ()=>{
    document.getElementById("Card").style.opacity = 1;
    document.getElementById("DigitalInterfaceCardHome").style.display="none";
    document.getElementById("mainscreenContainer").style.display="block";
    setShouldRedirect(true);
  }


  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <div className="Digitalinterfacewrapper" id="Card">
      <Swiper
          cssMode={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          className="mySwiper"
          // autoplay={{ delay: 3000 }}
        >
          {clients.map((clients, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="Cardcontainer">
                  <div className="Cardfixedcontainer">
                    <div className="Cardibutton">
                      <button className="btnlayers1">i</button>
                    </div>
                    <div className="Cardimagediv" id="diimg">
                      <img src={clients.img} alt="" />
                    </div>
                    <div className="Cardmaintext" style={clients.widtHeading} dangerouslySetInnerHTML={createMarkup(clients.review)}></div>
                    <div className="Cardunilateral" dangerouslySetInnerHTML={createMarkup(clients.text)}></div>
                  </div>

                  <div className="Cardparacontainer">
                    <div className="Cardpara" style={clients.widthPara}>
                      <p dangerouslySetInnerHTML={createMarkup(clients.Diparagraph1)}></p>
                      <p className="steamheadpara3" dangerouslySetInnerHTML={createMarkup(clients.Diparagraph2)}></p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      
  <div className="crossBtnContainer">
        <button className="SteamButton1" onClick={closeCardContainer}>
          <img src={Cross} alt="" />
        </button>
    </div>
    </div>
  );
};

export default Digitalinterface;

import React from 'react'
import './Cards.css'
import { Link } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import Genrator1 from "../../Assets/Genrator1.webp";
import Genrator2 from "../../Assets/Genrator2.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";

const Cards = () => {
    const clients = [
        {
          img: Genrator1,
          review: "GENERATOR",
          text: "SteamScape™ Generator",
          generatorparagraph1:
            "SteamScape™ Generator available in multiple sizes to accommodate a variety of shower spaces and a compact design for convenient and easily accessible installation locations.",
          generatorparagraph2:
            "SteamScape™ Generator features Proportional Steam Technology to regulate steam environment without temperature spikes.",
        },
        {
          img: Genrator2,
          review: "GENERATOR",
          text: "Pan & AUTO DRAIN",
          generatorparagraph1:
            "Generator Pan features elevated support for generator and solid-brass plumbing connection",
          generatorparagraph2:
            "Auto Drain will open automatically to purge the system after water in generator cools.",
        },
      ];
  return (
    <div className="Cardwrapper">
              <div className="Cardcontainer">
              
      <Swiper
        navigation={true}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
      >
        {clients.map((clients, index) => {
          return (
            <SwiperSlide key={index}>
                <div className='Cardfixedcontainer'>
                <div className="Cardibutton">
                  <button className="btnlayers1">i</button>
                </div>
                <div className="Cardimagediv">
                  <img src={clients.img} alt="" />{" "}
                </div>
                <div className="Cardmaintext">{clients.review}</div>
                <div className="Cardunilateral">{clients.text}</div>
                </div>

              <div className="Cardparacontainer">
                <div className='Cardpara'>
                  <p>{clients.generatorparagraph1}</p>
                  <p className="steamheadpara3">{clients.generatorparagraph2}</p>
                </div>
                

              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      </div>

      <Link to="/mainscreen">
        <button className="SteamButton1">
          <img src={Cross} alt="" />
        </button>
      </Link>
    </div>
  )
}

export default Cards


import React, { useState , useEffect, useRef } from 'react';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js'
import { Link ,useNavigate } from "react-router-dom";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import modelPath from '../../Model/deltatc.glb';
import notesModelPath from '../../Model/MusicNotes.glb';
import SmokeTexture from "../../Assets/tex_smoke.png";
import song1 from "../../audio/song1.mp3";
import song2 from "../../audio/song2.mp3";
import song3 from "../../audio/song3.mp3";
import song4 from "../../audio/song4.mp3";
import steam from "../../audio/steam.mp3";
import steamOn from "../../Assets/steamOn.png";
import steamOff from "../../Assets/steamOff.png";
import steamOn1 from "../../Assets/steamOn1.png";
import steamOff1 from "../../Assets/steamOff1.png";
console.log(modelPath);
const  ThreeContainer = ({loadModel}) => {
  const [isSteamOn, setSteamOn] = useState(false);
  const containerRef = useRef(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  var song = [song1, song2, song3, song4];
  var sound = null;
  var audioLoader = null;
  var lastHotpointOpened = 0;
  var isSteamRunning = false;
  const handleSteam = () => {
    setSteamOn(!isSteamOn);
    console.log(!isSteamOn);
    
    if(!isSteamOn){
      window.startParticles();
      
      document.getElementById("steamonoff").src = steamOff;
      document.getElementById("steamonoff1").src = steamOff1;
    }
    else{
      window.stopParticles();
      document.getElementById("steamonoff").src = steamOn;
      document.getElementById("steamonoff1").src = steamOn1;
    }
    // UpdateSteam(!isToggleOn)
  };
  window.handleSteam = handleSteam;

  useEffect(() => {
    if (shouldRedirect) {
      setTimeout(() => {
        setShouldRedirect(false)
        // navigate('/welcome');

        document.getElementById("welComeContainer").style.display="block";
        document.getElementById("loadingContainer").style.display="none";
      }, 400);
      
    }
  }, [shouldRedirect, navigate]);
  
  
  useEffect(() => {
    if (!containerRef.current) return;
    // console.log(loadModel);
    function wait(seconds) {
      return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }
    
    async function setFlagAfterDelay() {
      // Wait for 30 seconds
       await wait(5);
      
      // Set flag to true after waiting
      const flag = true;
      console.log('Flag set to true after 30 seconds');
    
      // Continue with any other logic here

      document.getElementById("bar").style.backgroundSize = "100% 100%";
          //if(percentComplete == 70){
      await wait(1);
      setShouldRedirect(true);
    }
    var mixer;
    var notesMixer = [null,null,null];
    var action ;
    var notesAction = [null,null,null];
    var labelRenderer;
    var isCameraAnimated;
    var targetRotationX, targetRotationY;
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    const scene = new THREE.Scene();
    var clock = new THREE.Clock();
    let particleCount = 100; // Increased for density
    let particleSize = 0.45;   // Adjust size as needed
    let isSteamAnimating = false;
    let smokeParticles = [];
    const threshold = 0.0005;  
    let targetRotationY1 = 0;
    let targetRotationZ1 = 0;
    var maxRotation = THREE.MathUtils.degToRad(15);
    var minRotation = THREE.MathUtils.degToRad(-15);

    var maxRotationUp = 0.15;
    var minRotationDown = -0.15;
    // maxRotationUp = -0.15;
    // minRotationDown = -0.45;
    const lerpFactor = 0.25;
    let accumulatedRotation = 0; 
    var isSongPlaying = false;
    const rectangeMeshName  = "112816_asm_UnilateralSteam_Head003"
    const squareMeshName = "112480_asm_ContempSquareSteam_Head004"
    var rectangleMesh,squareMesh = null;
    const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.25, 100 );
    var hotpoints = [];
    var door,wall;
    var controls;
    var isMain = true;
    var isEmulator = false;

    camera.position.set( -0.31, 0.45, 1.8);
    // camera.position.set( 0, 0.6, 1.3);
    camera.rotation.x = 0;
    camera.rotation.y = -0.2;
    camera.rotation.z = 0;
    // camera.rotation.x = 0;
    // camera.rotation.y = 0;
    // camera.rotation.z = 0;
    targetRotationY = camera.rotation.y;
				targetRotationX = camera.rotation.x;
        targetRotationY1 = camera.rotation.y;
				targetRotationZ1 = camera.rotation.x;
				// default camera position.

                // Camera Position: x=-0.14, y=0.70, z=1.53
                // testdelta.js:166 Camera Rotation: x=-0.14, y=-0.11, z=-0.02

    
    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio/1.5)
    containerRef.current.appendChild(renderer.domElement);

    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize( window.innerWidth, window.innerHeight );
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = '0px';
    labelRenderer.domElement.style.zIndex = 0;
    containerRef.current.appendChild( labelRenderer.domElement );
    
    if(isEmulator){
      controls = new OrbitControls( camera, labelRenderer.domElement );
      controls.minDistance = -200;
      controls.maxDistance = 10;
      controls.target.set( 0, 0, -1 );
    }
    const listener = new THREE.AudioListener();
      camera.add( listener );
  
      // create a global audio source
      sound = new THREE.Audio( listener );
      sound.hasPlaybackControl = true;
      // load a sound and set it as the Audio object's buffer
  
      audioLoader = new THREE.AudioLoader();
      audioLoader.load( song1, function( buffer ) {
          sound.setBuffer( buffer );
          // sound.setLoop( true );
          sound.setVolume( 0.6 );
          // sound.play();
      });
  
  
      const steamSound = new THREE.Audio( listener );
      var steamAudioLoader = new THREE.AudioLoader();

      function PlayPause(callback){
        // console.log(sound);
        if (sound.isPlaying) {
          sound.pause();
        }
        else{
          sound.play();
          console.log("before callback done");
          // Call the callback function if provided
          if (typeof callback === 'function') {
            console.log("callback done");
            callback(sound);
          }
        }
        
      }

      function ChangeSong(currentsong, callback){
        if (sound.isPlaying) {
          sound.stop();
        }
        audioLoader.load( song[currentsong-1], function( buffer ) {
            sound.setBuffer( buffer );
            // sound.setLoop( true );
            sound.play();
            console.log("before callback done");
            // Call the callback function if provided
            if (typeof callback === 'function') {
              console.log("callback done");
              callback(sound);
            }
        });
      }

      function ChangeVolume(currentbar){
        sound.setVolume( currentbar * 0.1);
      }

      function HideHotpoints(){
        labelRenderer.domElement.style.opacity = 0;
      }
      function ShowHotpoints(){
        labelRenderer.domElement.style.opacity = 1;
      }
      window.PlayPause = PlayPause;
      window.ChangeSong = ChangeSong;
      window.ChangeVolume = ChangeVolume;
      window.HideHotpoints = HideHotpoints;
      window.ShowHotpoints = ShowHotpoints;

    renderer.setSize(window.innerWidth, window.innerHeight);
    /*var controls = new OrbitControls( camera, labelRenderer.domElement );
    controls.minDistance = -200;
    controls.maxDistance = 10;
    controls.target.set( 0, 0, -1 );*/
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://raw.githubusercontent.com/crazyalok/GLTF-Compression/main/libs/draco/' );
    dracoLoader.setDecoderConfig( { type: 'js' } );

    const ktx2Loader = new KTX2Loader();
    ktx2Loader.setTranscoderPath("https://raw.githubusercontent.com/crazyalok/GLTF-Compression/main/libs/basis/" )
    ktx2Loader.detectSupport( renderer );
    
    if(loadModel){
    const loader = new GLTFLoader();
    loader.setDRACOLoader( dracoLoader );
    loader.setKTX2Loader( ktx2Loader );
    
      loader.load( modelPath, function ( gltf ) {		
        const model = gltf.scene;
        // baseModel = model;
        gltf.scene.scale.set(0.01,0.01,0.01);
        const object = gltf.scene.getObjectByName('Glass_Wall002');
        object.material.transmission = 0.34;
        object.material.transparent = true;
        object.material.opacity = 0.16;
        object.material.color.set(0xffffff);
        scene.add( gltf.scene );

        mixer = new THREE.AnimationMixer( model );
        action = mixer.clipAction( gltf.animations[0] );
        action.clampWhenFinished = true;
        action.setLoop(THREE.LoopOnce);
        
        squareMesh = gltf.scene.getObjectByName(squareMeshName);
        rectangleMesh = gltf.scene.getObjectByName(rectangeMeshName);
        squareMesh.visible = false;
        door = (scene.getObjectByName("Plane039"));
        wall = scene.getObjectByName("Plane055");
        setFlagAfterDelay();
        //setTimeout(() => {
          // document.getElementById("bar").style.backgroundSize = "100% 100%";
          //if(percentComplete == 70){
            // setShouldRedirect(true);
          //}
       // }, 1000);
        
        
    },
    function ( xhr ) {
      // console.log( `model ${xhr.loaded}% downloaded` );
      
          const percentComplete = xhr.loaded / 9936128 * 50;
          // console.log( `model ${Math.round( percentComplete, 2 )}% downloaded` );
          if(document.getElementById("bar") != null){
            document.getElementById("bar").style.backgroundSize = (41+percentComplete)+"% 100%";
            
          }

          
          
          // Here, you can also update any UI elements to reflect the loading progress
      });

      loader.load( notesModelPath, function ( gltf ) {
        const model = gltf.scene;
        var scale = 0.15;
        var positionY = 1.1
        model.scale.set(scale,scale,scale);
        model.position.set(0,positionY,0);
        scene.add( gltf.scene );
        notesMixer[0] = new THREE.AnimationMixer( model );
        notesAction[0] = notesMixer[0].clipAction( gltf.animations[0] );
        // console.log(notesAction[0]);
  
        const duplicateModel = gltf.scene.clone(); // Clone the original model
        duplicateModel.scale.set(scale,scale,scale);
        duplicateModel.position.set(0,positionY,0); // You can change the position if needed
        scene.add( duplicateModel );
        notesMixer[1] = new THREE.AnimationMixer( duplicateModel );
        notesAction[1] = notesMixer[1].clipAction( gltf.animations[0] );
  
        const duplicateModel1 = gltf.scene.clone(); // Clone the original model
        duplicateModel1.scale.set(scale,scale,scale);
        duplicateModel1.position.set(0,positionY,0);// You can change the position if needed
        scene.add( duplicateModel1 );
        notesMixer[2] = new THREE.AnimationMixer( duplicateModel1 );
        notesAction[2] = notesMixer[2].clipAction( gltf.animations[0] );
        // notesAction[0].play();
      });
      
    }

    
    function registerGyroHandler(){
      if (window.DeviceMotionEvent) {
        window.addEventListener("devicemotion", function(event) {
          let rotationRate = event.rotationRate;
          
          if (rotationRate) {
            let gyroscopeChange = rotationRate.beta * 0.0002;  // Convert the gyroscope reading to a meaningful camera rotation value.
            let gyroscopeChangeVertical = rotationRate.alpha * 0.0002;
            // If the rotation value from the gyroscope is larger than the threshold, 
            // accumulate it to our rotation variable.
            if (Math.abs(gyroscopeChange) > threshold) {
              targetRotationY1 += gyroscopeChange;
            }
            if (Math.abs(gyroscopeChangeVertical) > threshold) {
              targetRotationZ1 += gyroscopeChangeVertical;
            }
      
            // Bound the rotation to our predefined max and min.
            targetRotationY1 = Math.max(minRotation, Math.min(maxRotation, targetRotationY1));
            targetRotationZ1 = Math.max(minRotationDown, Math.min(maxRotationUp, targetRotationZ1));
            // camera.rotation.y = accumulatedRotation;
            // console.log(accumulatedRotation);
          }
        });
      }
    }
    window.registerGyroHandler = registerGyroHandler;

    /*

    speaker Install -0.5264648466611456 0.43314070008194705 0.13272906958563624
    Gen Install Intersection point: -0.5207802992885964 0.16559810638427727 0.1540230767200811

    Tablet Intersection point: 0.0768366484710073 0.6111772957926316 0.2965403953194618
    Intersection point: 0.3047039192887791 0.4998855216001325 0.13520213950751267

    Seat addon Intersection point: 0.21696456860273505 0.1818804761830583 0.2965403953194618
threejs.jsx:129 Intersection point: 0.1775307256892648 0.18247056954506988 0.06657105918992134

steam head
Intersection point: -0.4431234721382966 0.2916663119884183 0.111759991645813
threejs.jsx:129 Intersection point: -0.1488505108957845 0.18708215886810076 -0.2950079655204172

GLTFLoader
    */


function audioStopRoofAnimationPlayer(){
	if(notesAction[0].isRunning()){

    notesAction[0].stop();
		notesAction[0].reset();
		notesAction[0].time = 0;
		notesAction[1].stop();
		notesAction[1].reset();
		notesAction[1].time = 0;
		notesAction[2].reset();
		notesAction[2].time = 0;
		notesAction[2].stop();

		/*switch (animCounter) {
			case 1:
			{
				notesAction[0].clampWhenFinished = true;
				notesAction[0].setLoop(THREE.LoopOnce);
				notesAction[1].stop();
				notesAction[2].stop();
				break;
			}
			case 2:
			{
				notesAction[1].clampWhenFinished = true;
				notesAction[1].setLoop(THREE.LoopOnce);
				notesAction[2].stop();
				notesAction[0].stop();
				break;
			}
			case 3:
			{
				notesAction[2].clampWhenFinished = true;
				notesAction[2].setLoop(THREE.LoopOnce);
				notesAction[0].stop();
				notesAction[1].stop();
				break;
			}
			default:
				break;
		}*/
		
		/*action[4].clampWhenFinished = true;
		action[4].setLoop(THREE.LoopOnce);
		action[5].clampWhenFinished = true;
		action[5].setLoop(THREE.LoopOnce);*/
		clearTimeout(t1);
		t1 = null;

		clearTimeout(t2);
		t2 = null;
	}
}
var t1, t2;
var animCounter = 0;
function audioRoofAnimationPlayer(){
	
		notesAction[0].clampWhenFinished = false;
		notesAction[0].setLoop(THREE.LoopRepeat);
		// notesAction[0].reset();
		notesAction[0].play();
		animCounter = 1;
		t1 = setTimeout(() => {
			notesAction[1].clampWhenFinished = false;
			notesAction[1].setLoop(THREE.LoopRepeat);
			// notesAction[1].reset();
			notesAction[1].play();
			t2 = setTimeout(() => {
				notesAction[2].clampWhenFinished = false;
				notesAction[2].setLoop(THREE.LoopRepeat);
				// notesAction[2].reset();
				notesAction[2].play();
			}, 2500);
		}, 2500);


		notesMixer[0].addEventListener('loop', function (event) {
			console.log("anim 1 looped");
			animCounter = 1;
		});
		notesMixer[1].addEventListener('loop', function (event) {
			console.log("anim 2 looped");
			animCounter = 2;
		});
		notesMixer[2].addEventListener('loop', function (event) {
			console.log("anim 3 looped");
			animCounter = 3;
		});

	
}
/*function audioRoofAnimationPlayer(){
	if(!notesAction[0].isRunning()){
		notesAction[0].clampWhenFinished = false;
		notesAction[0].setLoop(THREE.LoopRepeat);
		notesAction[0].reset();
		notesAction[0].play();
		setTimeout(() => {
			notesAction[1].clampWhenFinished = false;
			notesAction[1].setLoop(THREE.LoopRepeat);
			notesAction[1].reset();
			notesAction[1].play();
			setTimeout(() => {
				notesAction[2].clampWhenFinished = false;
				notesAction[2].setLoop(THREE.LoopRepeat);
				notesAction[2].reset();
				notesAction[2].play();
			}, 2500);
		}, 2500);
	}
}*/
window.audioRoofAnimationPlayer = audioRoofAnimationPlayer;
window.audioStopRoofAnimationPlayer = audioStopRoofAnimationPlayer;
   function enableSquareSteamHead(){
    squareMesh.visible = true;
		rectangleMesh.visible = false;
   }
   function enableRectangleSteamHead(){
    squareMesh.visible = false;
		rectangleMesh.visible = true;
   }
   window.enableSquareSteamHead = enableSquareSteamHead;
   window.enableRectangleSteamHead = enableRectangleSteamHead;
function StartSteamSound(){
  // create a global audio source
  
  steamSound.hasPlaybackControl = true;
  // load a sound and set it as the Audio object's buffer

  
  steamAudioLoader.load( steam, function( buffer ) {
      steamSound.setBuffer( buffer );
      steamSound.setLoop( true );
      steamSound.setVolume( 0.05 );
      steamSound.play();
  });
}
function StopSteamSound(){
  steamSound.stop();
}
  function startParticles() {
    if (!isSteamAnimating) {
      isSteamAnimating = true;
      CreateSteam();
      StartSteamSound();
    }
  }
  function stopParticles() {
    isSteamAnimating = false;  // This will trigger the fade-out in the animate loop
    StopSteamSound();
  }
  window.startParticles = startParticles;
  window.stopParticles = stopParticles;
  function CreateSteam(){
    /*let geometry = new THREE.BoxGeometry(2, 1.8, 1);
    let material = new THREE.MeshBasicMaterial({ color: 0x00ff00, opacity : 0.5, transparent :true }); // Use wireframe so we can see inside
    let cuboid = new THREE.Mesh(geometry, material);
    cuboid.position.set(-0.2,-0.15,-1.8)
    scene.add(cuboid);*/
    
    
      let smokeTexture = new THREE.TextureLoader().load(SmokeTexture);  // Replace with your smoke texture path
    
      for (let i = 0; i < particleCount; i++) {
        let smokeMaterial = new THREE.SpriteMaterial({ map: smokeTexture, transparent: true, opacity: 0 });
        let smokeParticle = new THREE.Sprite(smokeMaterial);
        smokeParticle.position.set((Math.random() - 0.5) * 2 - 0.2, -2 + Math.random() * 1.2, (Math.random() - 0.5) * 1 -1.8);
        
        let angle = Math.random() * Math.PI * 2; // Random angle for circular motion
        smokeParticle.velocity = new THREE.Vector3(Math.cos(angle) * 0.0001, 0.0003 + Math.random() * 0.0002, Math.sin(angle) * 0.0001);  // Consistent velocities
        
        smokeParticle.scale.set(particleSize, particleSize, particleSize);
        smokeParticles.push(smokeParticle);
        scene.add(smokeParticle);
      }
    }
    function AddHotPoints(){
      const earthDiv1 = document.getElementById("hotPointSpeakerInstall");

      const earthLabel1 = new CSS2DObject( earthDiv1 );
      earthLabel1.position.set(-0.5264648466611456, 0.43314070008194705, 0.13272906958563624 );
      scene.add( earthLabel1 );
      hotpoints.push(earthLabel1);
  
      const earthDiv2 = document.getElementById("hotPointGeneratorInstall");
  
      const earthLabel2 = new CSS2DObject( earthDiv2 );
      earthLabel2.position.set(-0.5207802992885964, 0.16559810638427727, 0.1540230767200811);
      scene.add( earthLabel2 );
      hotpoints.push(earthLabel2);
  
      const earthDiv3 = document.getElementById("hotPointDigitial");
  
      const earthLabel3 = new CSS2DObject( earthDiv3 );
      earthLabel3.position.set(0.3047039192887791, 0.4998855216001325, 0.13520213950751267);
      scene.add( earthLabel3 );
      hotpoints.push(earthLabel3);
  
      const earthDiv4 = document.getElementById("hotPointseatAddon");
  
      const earthLabel4 = new CSS2DObject( earthDiv4 );
      earthLabel4.position.set( 0.1775307256892648, 0.18247056954506988, 0.06657105918992134);
      scene.add( earthLabel4 );
      hotpoints.push(earthLabel4);
  
      const earthDiv5 = document.getElementById("hotPointSteamHead");
  
      const earthLabel5 = new CSS2DObject( earthDiv5 );
      earthLabel5.position.set(-0.1488505108957845, 0.18708215886810076 ,-0.2950079655204172);
      scene.add( earthLabel5 );
      hotpoints.push(earthLabel5);

      const earthDiv6 = document.getElementById("hotPointSpeakers");
  
      const earthLabel6 = new CSS2DObject( earthDiv6 );
      earthLabel6.position.set(-0.007185997170315567, 0.9264440858346701, 0.2965403953194618);
      scene.add( earthLabel6 );
      hotpoints.push(earthLabel6);
    }

    window.AddHotPoints = AddHotPoints;
    

    function focusInstall(){
      console.log("fun calling");
      document.querySelector(".steamToggle1").style.opacity = 0;
      document.querySelector(".steamToggle1").style.pointerEvents = 'none';
      setTimeout(() => {
        action.reset();
        action.timeScale = 1;
        action.setLoop(THREE.LoopOnce);  
        action.play();
      }, 1500);
      const targetPosition = new THREE.Vector3(-0.54, 0.45, 1.14)
      const targetRotation = new THREE.Vector3(-0.30,  0, 0)
      
      isCameraAnimated = true;
      camera.rotation.reorder('YXZ');
      new TWEEN.Tween(camera.position)
      .to({ x: targetPosition.x,y: targetPosition.y,z:targetPosition.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      
      .start();
      
    
      new TWEEN.Tween(camera.rotation)
      .to({ x: targetRotation.x,y: targetRotation.y,z:targetRotation.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .onComplete(()=>{
        targetRotationX = -0.3;
        targetRotationY = 0;
        targetRotationY1 = 0;
        targetRotationZ1 = -0.3;
      })
      .start();
      setTimeout(() => {
        maxRotation = THREE.MathUtils.degToRad(7);
        minRotation = THREE.MathUtils.degToRad(-7);
        maxRotationUp = -0.15;
        minRotationDown = -0.45;
        targetRotationX = -0.3;
        targetRotationY = 0;
        targetRotationY1 = 0;
        targetRotationZ1 = -0.3;
        isMain = false;
        isCameraAnimated = false;
      }, 2000);
    }
    window.focusInstall = focusInstall;

    function focusMainWithDelay(){
      
      action.paused = false;
		  action.timeScale = -1;
		  action.setLoop(THREE.LoopOnce);  
		  action.play();
      /*camera.position.set( -0.3, 0.56, 1.4);
      camera.rotation.x =  -0.08;
      camera.rotation.y = -0.27
      camera.rotation.z = -0.02;*/
      
      setTimeout(() => {
        const targetPosition = new THREE.Vector3(-0.31, 0.45, 1.8)
        const targetRotation = new THREE.Vector3(0,-0.2,0)
        
        new TWEEN.Tween(camera.position)
        .to({ x: targetPosition.x,y: targetPosition.y,z:targetPosition.z}, 2000) 
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
      
        new TWEEN.Tween(camera.rotation)
        .to({ x: targetRotation.x,y: targetRotation.y,z:targetRotation.z}, 2000) 
        .easing(TWEEN.Easing.Quadratic.Out)
        .onComplete(()=>{
          
          targetRotationX = 0;
          targetRotationY = -0.2;
          targetRotationZ1 = 0;
          targetRotationY1 = -0.2;
          maxRotation = THREE.MathUtils.degToRad(15);
          minRotation = THREE.MathUtils.degToRad(-15);
          maxRotationUp = 0.15;
          minRotationDown = -0.15;
          isMain = true;
          isCameraAnimated = false;
          document.querySelector(".steamToggle1").style.opacity = 1;
          document.querySelector(".steamToggle1").style.pointerEvents = 'auto';
        })
        .start();
        setTimeout(() => {
          /*maxRotation = THREE.MathUtils.degToRad(15);
          minRotation = THREE.MathUtils.degToRad(-15);
          maxRotationUp = 0.15;
          minRotationDown = -0.15;
          targetRotationX = 0;
          targetRotationY = -0.2;
          targetRotationZ1 = 0;
          targetRotationY1 = -0.2;
          isMain = true;
          isCameraAnimated = false;
          document.querySelector(".steamToggle1").style.opacity = 1;
          document.querySelector(".steamToggle1").style.pointerEvents = 'auto';*/
        }, 2000);
     }, 1200);
      
    }
    
    function focusMain(){
      action.paused = false;
		  action.timeScale = -1;
		  action.setLoop(THREE.LoopOnce);  
		  action.play();
      /*camera.position.set( -0.3, 0.56, 1.4);
      camera.rotation.x =  -0.08;
      camera.rotation.y = -0.27
      camera.rotation.z = -0.02;*/
      //setTimeout(() => {
        const targetPosition = new THREE.Vector3(-0.31, 0.45, 1.8)
        const targetRotation = new THREE.Vector3(0,-0.2,0)
        
        new TWEEN.Tween(camera.position)
        .to({ x: targetPosition.x,y: targetPosition.y,z:targetPosition.z}, 2000) 
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
      
        new TWEEN.Tween(camera.rotation)
        .to({ x: targetRotation.x,y: targetRotation.y,z:targetRotation.z}, 2000) 
        .easing(TWEEN.Easing.Quadratic.Out)
        .onComplete(()=>{
          targetRotationX = 0;
          targetRotationY = -0.2;
          targetRotationZ1 = 0;
          targetRotationY1 = -0.2;
        })
        .start();
        setTimeout(() => {
          maxRotation = THREE.MathUtils.degToRad(15);
          minRotation = THREE.MathUtils.degToRad(-15);
          maxRotationUp = 0.15;
          minRotationDown = -0.15;
          targetRotationX = 0;
          targetRotationY = -0.2;
          targetRotationZ1 = 0;
          targetRotationY1 = -0.2;
          isMain = true;
          isCameraAnimated = false;
        }, 2000);
     // }, 1200);
      
    }

    function focusSteamHead(){
      isCameraAnimated = true;
      const targetPosition = new THREE.Vector3(-0.12, 0.47, 1)
      const targetRotation = new THREE.Vector3(THREE.MathUtils.degToRad(-18),0,0)
      
      new TWEEN.Tween(camera.position)
      .to({ x: targetPosition.x,y: targetPosition.y,z:targetPosition.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();
    
      new TWEEN.Tween(camera.rotation)
      .to({ x: targetRotation.x,y: targetRotation.y,z:targetRotation.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .onComplete(()=>{
        targetRotationX = camera.rotation.x;
        targetRotationY = camera.rotation.y;
      })
      .start();
    }

    function focusSpeakers(){
      isCameraAnimated = true;
      const targetPosition = new THREE.Vector3(-0.12, 0.6, 1.4)
      const targetRotation = new THREE.Vector3(THREE.MathUtils.degToRad(2),THREE.MathUtils.degToRad(-5.4),0)
      
      new TWEEN.Tween(camera.position)
      .to({ x: targetPosition.x,y: targetPosition.y,z:targetPosition.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();
    
      new TWEEN.Tween(camera.rotation)
      .to({ x: targetRotation.x,y: targetRotation.y,z:targetRotation.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .onComplete(()=>{
        targetRotationX = camera.rotation.x;
        targetRotationY = camera.rotation.y;
      })
      .start();
    }

    
    
    window.focusMain = focusMain;
    window.focusMainWithDelay = focusMainWithDelay;
    window.focusSteamHead = focusSteamHead;
    window.focusSpeakers = focusSpeakers;
    // scene.js:109 Camera Rotation: x=-1.45, y=1.20, z=1.44
    /*function focusGenInstall(){
      const targetPosition = new THREE.Vector3(0, -0.67,-0.65)
      const targetRotation = new THREE.Vector3(-1.45, 1.20, 1.44)
      isCameraAnimated = true;
      camera.rotation.reorder('XYZ');
      new TWEEN.Tween(camera.position)
      .to({ x: targetPosition.x,y: targetPosition.y,z:targetPosition.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();

      new TWEEN.Tween(camera.rotation)
      .to({ x: targetRotation.x,y: targetRotation.y,z:targetRotation.z}, 2000) 
      .easing(TWEEN.Easing.Quadratic.Out)
      .onComplete(()=>{
        targetRotationX = camera.rotation.x;
        targetRotationY = camera.rotation.y;
      })
      .start();
    }*/

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            console.log('Camera Position:', camera.position);
            console.log('Camera Rotation:', camera.rotation);
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    window.addEventListener('click', onClick, false);

function onClick(event) {
  // Convert mouse position to normalized device coordinates (-1 to +1)
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  // Update the ray with the camera and mouse position
  raycaster.setFromCamera(mouse, camera);

  // Calculate objects intersecting the ray
  const intersects = raycaster.intersectObjects(scene.children);

  if (intersects.length > 0) {
    // intersects[0] contains the closest intersection
    /*console.log("Intersection point:", intersects[0].point.x,intersects[0].point.y,intersects[0].point.z );
    const earthDiv = document.createElement( 'div' );
    earthDiv.className = 'label';
    earthDiv.textContent = 'Earth';

    const earthLabel = new CSS2DObject( earthDiv );
    earthLabel.position.set(intersects[0].point.x,intersects[0].point.y,intersects[0].point.z );
    scene.add( earthLabel );*/
    /*const digitalInterfaceHotpointElement = document.getElementById("hotPointSpeakers");
    var digitalInterfaceHotpoint = new CSS2DObject( digitalInterfaceHotpointElement );
    console.log(intersects[0].point.x,intersects[0].point.y,intersects[0].point.z);
    digitalInterfaceHotpoint.position.set( intersects[0].point.x,intersects[0].point.y,intersects[0].point.z );
    scene.add( digitalInterfaceHotpoint );*/
  }
}



    const animate = (time) => {
      requestAnimationFrame(animate);
    //   cube.rotation.x += 0.01;
    //   cube.rotation.y += 0.01;
    if(isEmulator){
      controls.update(); 
    }
    TWEEN.update(time);
      // controls.update(); 
      const delta = clock.getDelta();

				// mixer.update( delta );
					if(mixer != null){
						mixer.update( delta );
					}
          notesMixer.forEach(mix => {
            if(mix != null){
              mix.update( delta );
            }
          });

          if(!isCameraAnimated){
            camera.rotation.y += (targetRotationY1 - camera.rotation.y) * lerpFactor;
            camera.rotation.x += (targetRotationZ1 - camera.rotation.x) * lerpFactor ;
          }
          /*if(!isCameraAnimated && !isMain){
            camera.rotation.y += (targetRotationY1 - camera.rotation.y) * lerpFactor ;
            camera.rotation.x += (targetRotationZ1 - camera.rotation.x) * lerpFactor ;
          }*/
          for (let i = 0; i < smokeParticles.length; i++) {
            let particle = smokeParticles[i];
            if (!isSteamAnimating) {
              // Decrease opacity when stopping
              particle.material.opacity -= 0.01;
              
              // Optional: Slow down particle movement if desired
              particle.velocity.multiplyScalar(0.98);
          
              // Remove particle from scene when fully transparent
              if (particle.material.opacity <= 0) {
                scene.remove(particle);
              }
            } else {
              // Your regular code for moving particles, resetting, etc.
              particle.position.add(particle.velocity);  // Add the velocity to the position
        
              // Reset particle when it goes outside the cuboid
              if (
                particle.position.x > 0.25 || 
                particle.position.x < -0.25  ||
                particle.position.y > 1 || 
                particle.position.y < -0.1||
                particle.position.z > 0.25  || 
                particle.position.z < -0.25 
              ) {
              
                particle.position.set(
                  (Math.random() - 0.5) * 0.5 ,
                  -0.2 +  Math.random() * 2, // Reset to the bottom of the cuboid
                  (Math.random() - 0.5) * 0.5 
                );
                particle.material.opacity = 0;
                
                // Set a new circular motion velocity
                let angle = Math.random() * Math.PI * 2;
                particle.velocity.set(Math.cos(angle) * 0.0001, 0.00075 + Math.random() * 0.0002, Math.sin(angle) * 0.0001);
              }
          
              // Animate opacity
              if (particle.material.opacity < 0.08) {
                particle.material.opacity += 0.001;
              }
  
            }
            
  
            
          }

          hotpoints.forEach(hotpoint => {
            if(hotpoint !=null && door !=null && wall !=null ){
              hotpoint.getWorldPosition(raycaster.ray.origin);
              var rd=camera.position.clone().sub(raycaster.ray.origin).normalize();
              raycaster.ray.direction.set(rd.x,rd.y,rd.z);
              var hits=raycaster.intersectObjects([door,wall]);
              if(hits.length>0 ){ 
                hotpoint.visible=false; 
              }
              else{
                hotpoint.visible=true; 
              }
            }
          });
      renderer.render(scene, camera);
      labelRenderer.render( scene, camera );
    };

    const handleWindowResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleWindowResize);
    animate();

    return () => {
    //   window.removeEventListener('resize', handleWindowResize);
      containerRef.current.removeChild(renderer.domElement);
      containerRef.current.removeChild(labelRenderer.domElement);


      
    //   scene.dispose();
    //   geometry.dispose();
    //   material.dispose();
    };
  }, [loadModel]);

  const expand_1 = (e) => {
    console.log("btn clicked");
    
    const expandItem1 = document.querySelector("#expand1");
    const IElement = document.querySelector("#digital > span");
    const digital = document.querySelector('#digital');
    if(digital.classList.contains('digitalopened')){
      lastHotpointOpened = 0;
      expandItem1.classList.remove('IExpand');
      
      setTimeout(() => {
        digital.classList.remove('digitalopened');
        expandItem1.querySelector("span").style.pointerEvents = "none"
      }, 300);
    }
    else{
      // console.log(IElement.style.backgroundColor);
      CloseLastHotpoint();
      lastHotpointOpened = 1;
      
      if(IElement.style.backgroundColor != "#9B9B9B"){
        IElement.style.backgroundColor = "#9B9B9B";
      }
      digital.classList.add('digitalopened');
      setTimeout(() => {
        expandItem1.classList.add('IExpand');
        expandItem1.querySelector("span").style.pointerEvents = "auto"
      }, 300);
      
    }
  };
  
  const expand_2 = (e) => {
    console.log("btn clicked");
    const expandItem2 = document.querySelector("#expand2");
    const IElement = document.querySelector("#add > span");
    const add = document.querySelector('#add');
    if(add.classList.contains('addopened')){
      expandItem2.classList.remove('IExpand1');
      lastHotpointOpened = 0;
      setTimeout(() => {
        add.classList.remove('addopened');
        expandItem2.querySelector("span").style.pointerEvents = "none"
      }, 300);
    }
    else{
      CloseLastHotpoint();
      lastHotpointOpened = 2;
      if(IElement.style.backgroundColor != "#9B9B9B"){
        IElement.style.backgroundColor = "#9B9B9B";
      }
      add.classList.add('addopened');
      setTimeout(() => {
        expandItem2.classList.add('IExpand1');
        expandItem2.querySelector("span").style.pointerEvents = "auto"
      }, 300);
    }
  };
  
  const expand_3 = (e) => {
    console.log("btn clicked");
    const expandItem3 = document.querySelector("#expand3");
    const IElement = document.querySelector("#steamHeadHotpointButton > span");
    const steamHeadHotpointButton = document.querySelector('#steamHeadHotpointButton');
    if(steamHeadHotpointButton.classList.contains('steamHeadHotpointButtonopened')){
      lastHotpointOpened = 0;
      expandItem3.classList.remove('IExpand1');
      
      setTimeout(() => {
        steamHeadHotpointButton.classList.remove('steamHeadHotpointButtonopened');
        expandItem3.querySelector("span").style.pointerEvents = "none"
      }, 300);
    }
    else{
      CloseLastHotpoint();
      lastHotpointOpened = 3;
      
      if(IElement.style.backgroundColor != "#9B9B9B"){
        IElement.style.backgroundColor = "#9B9B9B";
      }
      steamHeadHotpointButton.classList.add('steamHeadHotpointButtonopened');
      setTimeout(() => {
        expandItem3.classList.add('IExpand1');
        expandItem3.querySelector("span").style.pointerEvents = "auto"
      }, 300);
    }
  };
  const expand_4 = (e) => {
    console.log("btn clicked");
    const expandItem4 = document.querySelector("#expand4");
    const IElement = document.querySelector("#addonsInstallationHotpointButton > span");
    const addonsInstallationHotpointButton = document.querySelector('#addonsInstallationHotpointButton');
    if(addonsInstallationHotpointButton.classList.contains('addonsInstallationHotpointButtonopened')){
      lastHotpointOpened = 0;
      expandItem4.classList.remove('IExpand1');
      
      setTimeout(() => {
        addonsInstallationHotpointButton.classList.remove('addonsInstallationHotpointButtonopened');
        expandItem4.querySelector("span").style.pointerEvents = "none"
      }, 300);
    }
    else{
      CloseLastHotpoint();
      lastHotpointOpened = 4;
      
      if(IElement.style.backgroundColor != "#9B9B9B"){
        IElement.style.backgroundColor = "#9B9B9B";
      }
      addonsInstallationHotpointButton.classList.add('addonsInstallationHotpointButtonopened');
      setTimeout(() => {
        expandItem4.classList.add('IExpand1');
        expandItem4.querySelector("span").style.pointerEvents = "auto"
      }, 300);
    }
  };
  const expand_5 = (e) => {
    console.log("btn clicked");
    const expandItem5 = document.querySelector("#expand5");
    const IElement = document.querySelector("#generatorinstallHotpointButton > span");
    const generatorinstallHotpointButton = document.querySelector('#generatorinstallHotpointButton');
    if(generatorinstallHotpointButton.classList.contains('generatorinstallHotpointButtonopened')){
      lastHotpointOpened = 0;
      expandItem5.classList.remove('IExpand1');
      
      setTimeout(() => {
        generatorinstallHotpointButton.classList.remove('generatorinstallHotpointButtonopened');
        expandItem5.querySelector("span").style.pointerEvents = "none"
      }, 300);
    }
    else{
      CloseLastHotpoint();
      lastHotpointOpened = 5;
      
      if(IElement.style.backgroundColor != "#9B9B9B"){
        IElement.style.backgroundColor = "#9B9B9B";
      }
      generatorinstallHotpointButton.classList.add('generatorinstallHotpointButtonopened');
      setTimeout(() => {
        expandItem5.classList.add('IExpand1');
        expandItem5.querySelector("span").style.pointerEvents = "auto"
      }, 300);
    }
  };

  const expand_6 = (e) => {
    console.log("btn clicked");
    const expandItem6 = document.querySelector("#expand6");
    const IElement = document.querySelector("#speakerHotpointButton > span");
    const speakerHotpointButton = document.querySelector('#speakerHotpointButton');
    if(speakerHotpointButton.classList.contains('speakerHotpointButtonopened')){
      lastHotpointOpened = 0;
      expandItem6.classList.remove('IExpand1');
      
      setTimeout(() => {
        speakerHotpointButton.classList.remove('speakerHotpointButtonopened');
        expandItem6.querySelector("span").style.pointerEvents = "none"
      }, 300);
    }
    else{
      CloseLastHotpoint();
      lastHotpointOpened = 6;
      
      if(IElement.style.backgroundColor != "#9B9B9B"){
        IElement.style.backgroundColor = "#9B9B9B";
      }
      speakerHotpointButton.classList.add('speakerHotpointButtonopened');
      setTimeout(() => {
        expandItem6.classList.add('IExpand1');
        expandItem6.querySelector("span").style.pointerEvents = "auto"
      }, 300);
    }
  };

  

  function CloseLastHotpoint(){
    console.log(lastHotpointOpened);
    switch (lastHotpointOpened) {
      case 0:
        
        break;
      case 1:
        expand_1();
        break;
      case 2:
        expand_2();
        break;
      case 3:
        expand_3();
        break;
      case 4:
        expand_4();
        break;
      case 5:
        expand_5();
        break;
      case 6:
        expand_6();
        break;

      default:
        break;
    }
  }

  const handleAddOnsClick = () => {
      document.getElementById("AddonsCardHome").style.display="block";
      document.getElementById("mainscreenContainer").style.display="none";
  }

  const handleSteamHeadClick = () => {
      document.getElementById("SteamHeadCardHome").style.display="block";
      document.getElementById("mainscreenContainer").style.display="none";
  }

  const handleDigitalInterface = () => {
    document.getElementById("DigitalInterfaceCardHome").style.display="block";
    document.getElementById("mainscreenContainer").style.display="none";
  }

  const handleAddOnsTwo = () => {
    document.getElementById("AddonsTwoCardHome").style.display="flex";
    document.getElementById("mainscreenContainer").style.display="none";
  }

  const handleGenerator = () => {
    document.getElementById("GeneratorCardHome").style.display="block";
    document.getElementById("mainscreenContainer").style.display="none";
  }
  const handleSpeaker = () => {
    document.getElementById("AddonsSpeakerContainer").style.display="flex";
    document.getElementById("mainscreenContainer").style.display="none";
  }

  return ( 
    <div>
      <div ref={containerRef} style={{ width: '100%', height: '100%' }} ></div>
      <div id="hotPointDigitial" className="hi">
        <div className="Btnlayer">
          <button className="btnlayer2" id="digital" onClick={expand_1} > {  /*  onClick={expand_1}  166px  */}
            <span className="i">i</span>
            <div className="generatorLink" id="expand1">
              <span className="generator" onClick={handleDigitalInterface}>DIGITAL INTERFACE</span>
            </div>
          </button>
        </div>
      </div>

      <div id="hotPointseatAddon" className="hey">
        <div className="Btnlayer">
          <button className="btnlayer2" id="add" onClick={expand_2}>
            <span className="i">i</span>
            <div className="generatorLink" id="expand2">
              <span className="generator" onClick={handleAddOnsClick}>SHOWER SEAT</span>
            </div>
          </button>
        </div>
      </div>

      <div id="hotPointSteamHead" className="steamHeadHotpoint">
        <div className="Btnlayer">
          <button className="btnlayer2" id="steamHeadHotpointButton" onClick={expand_3}>
            <span className="i">i</span>
            <div  className="generatorLink" id="expand3">
              <span className="generator" onClick={handleSteamHeadClick}>STEAM HEAD</span>
            </div>
          </button>
        </div>
      </div>

      <div id="hotPointSpeakerInstall" className="addonsInstallationHotpoint">
        <div className="Btnlayer">
          <button className="btnlayer2" id="addonsInstallationHotpointButton" onClick={expand_4}>
            <span className="i">i</span>
            <div className="generatorLink" id="expand4">
              <span className="generator" onClick={handleAddOnsTwo}>Audio Speaker System</span>
            </div>
          </button>
        </div>
      </div>


      <div id="hotPointGeneratorInstall" className="generatorinstallHotpoint">
        <div className="Btnlayer">
          <button className="btnlayer2" id="generatorinstallHotpointButton" onClick={expand_5}>
            <span className="i">i</span>
            <div className="generatorLink" id="expand5">
              <span className="generator" onClick={handleGenerator}>GENERATOR</span>
            </div>
          </button>
        </div>
      </div>

      <div id="hotPointSpeakers" className="generatorinstallHotpoint">
        <div className="Btnlayer">
          <button className="btnlayer2" id="speakerHotpointButton" onClick={expand_6}>
            <span className="i">i</span>
            <div className="generatorLink" id="expand6">
              <span className="generator" onClick={handleSpeaker}>AUDIO SPEAKER SYSTEM</span>
            </div>
          </button>
        </div>
      </div>

      </div>

      
    
  );
}

export default ThreeContainer;



import React, { useState ,useEffect } from "react";
import { Link,useNavigate     } from "react-router-dom";
import "./SteamView.css";
import Cross from "../../Assets/Cross.svg";
import Rectangleicon from "../../Assets/Rectangle1.webp";
import steamOn from "../../Assets/steamOn.png";
import steamOff from "../../Assets/steamOff.png";
import steamOn1 from "../../Assets/steamOn1.png";
import steamOff1 from "../../Assets/steamOff1.png";
import Squareicon from "../../Assets/SquareIcon.png";

const SteamView = ({steam, UpdateSteam, steamhead1, UpdateSteamHead1}) => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  const handleToggle = () => {
    window.handleSteam();
  };

  // window.handleToggle = handleToggle;
  const CloseSteamContainer = () => {
    document.getElementById("steamScreenContainer").style.opacity = 0;
    setTimeout(() => {
      // navigate('/mainscreen');
      document.getElementById("mainscreenContainer").style.display="block";
      document.getElementById("steamScreenContainer").style.display="none";
      
    }, 400);
    window.focusMain();
    window.ShowHotpoints();
    // setShouldRedirect(true);
   }
  useEffect(() => {
      
      // if (steamContainerRef.current) {
        // console.log("Element is now in the DOM!", steamContainerRef.current);
        setTimeout(() => {
          
        }, 100);
        
      // }
  }, []);
  useEffect(() => {
    if (shouldRedirect) {
      
      
    }
  }, [shouldRedirect, navigate]);
  const rectangleSteam = ()=>{
    const rectsteamButton = document.getElementById('rectsteamHead');
    const sqsteamButton = document.getElementById('sqsteamHead');
    if(steamhead1){
      rectsteamButton.style.opacity = 1;
      sqsteamButton.style.opacity = 0.5;
      UpdateSteamHead1(false);
      window.enableRectangleSteamHead();
    }
  };
  const squareSteam = ()=>{
    
    const rectsteamButton = document.getElementById('rectsteamHead');
    const sqsteamButton = document.getElementById('sqsteamHead');
    console.log(sqsteamButton.style.opacity);
    if(!steamhead1){
      sqsteamButton.style.opacity = 1;
      rectsteamButton.style.opacity = 0.5;
      UpdateSteamHead1(true);
      window.enableSquareSteamHead();
    }
  };

  const handleReturnToSteamView = () => {
    document.getElementById("SteamHeadCardHome").style.display = "block";
    // document.getElementById("closeSteamContainerId").style.display = "none";
    document.getElementById("steamScreenContainer").style.display = "none";
  }
  return (
    <div className="Steamwrapper">
      <div id="steamContainer" className="Steamwwcontainer">
        <div className="SteamHeader">
          <span>STEAM</span>
          {/* <label className="ToggleSwitch">
            <input
              type="checkbox"
              checked={isToggleOn}
              onChange={handleToggle}
            />
            <span className="Slider"></span>
          </label> */}
          <button className="steamToggle" onClick={handleToggle}>
            <img id="steamonoff" src={steamOn} alt="" />
          </button>
        </div>
        <div className="ImageContainer">
          <div id="rectsteamHead" className="ImageDiv1" onClick={rectangleSteam}>
            <img src={Rectangleicon} alt="" />
            <div className="TextBelowImage1">Unilateral</div>
          </div>
          <div  id="sqsteamHead" className="ImageDiv2" onClick={squareSteam}>
            <img src={Squareicon} alt="" />
            <div className="TextBelowImage2">COntemporary SQUARE</div>
          </div>
        </div>
        <div className="learnMore" >
        <div className="LearnMore"></div>
        </div>
      </div>
      <button className="SteamButton" id="closeSteamContainerId" onClick={CloseSteamContainer}>
        <img src={Cross} alt="" />
      </button>
    </div>
  );
};

export default SteamView;

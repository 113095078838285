import React, { useState,useEffect } from "react";
import "./MusicScreen.css";
import { Link,useNavigate } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import next from "../../Assets/next.png";
import play from "../../Assets/PlayButton.png";
import pause from "../../Assets/PauseButton.png";
import prev from "../../Assets/previous.png";
import volbar from "../../Assets/volumebar.png";

const MusicScreen = ({ song, updateSong, bar, updateBar}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  var currentBar = bar;
    var currentsong = song;
    var  songNames = ["MEDITATIVE", "CALMING", "SMOOTH", "INSPIRING"];
      // console.log(currentBar);
   
      
  const navigate = useNavigate();
  
  const togglePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    console.log(!isPlaying);
    if(!isPlaying){
      document.querySelector("#playpause").src = pause;
      window.audioRoofAnimationPlayer();
    }
    else{
      document.querySelector("#playpause").src = play;
      window.audioStopRoofAnimationPlayer();
    }
    
    window.PlayPause(function(sound) {
      // Perform the tasks you need with the 'sound' object here
      console.log('The song has been changed and is now playing.');
      sound.source.onended = handleSongEnd;
      // For example, you might want to update the UI or trigger another action
    });
  };

  const nextTrack = () => {
    // Implement your logic to play the next track
    if(currentsong <= 4){
      
      if(currentsong == 4){
        currentsong = 0;
      }
      currentsong++;
      updateSong(currentsong);
      document.getElementById("songname").style.opacity = 0;
      document.getElementById("songname").style.transform = "translateX(50px)";
      setTimeout(() => {
        document.getElementById("songname").style.transform = "translateX(-50px)";
        document.getElementById("songname").innerText = songNames[currentsong-1];
        setTimeout(() => {
            document.getElementById("songname").style.opacity = 1;
            document.getElementById("songname").style.transform = "translateX(0px)";
            // audioRoofTextureChanger(currentsong-1);
            setTimeout(() => {
                // audioRoofAnimationPlayer(true);
                setIsPlaying(true);
                document.querySelector("#playpause").src = pause;
                window.audioRoofAnimationPlayer();
            }, 300);
        }, 300);
      }, 400);
      window.ChangeSong(currentsong, function(sound) {
        // Perform the tasks you need with the 'sound' object here
        console.log('The song has been changed and is now playing.');
        sound.source.onended = handleSongEnd;
        // For example, you might want to update the UI or trigger another action
      });
      
    }
  };
  function handleSongEnd() {
    // The sound has finished playing
    
    // currentsong++;
    if(currentsong == 4){
        currentsong = 0;
    }
    console.log("songchange",currentsong);
    // Now load the next song, or loop back to the start, or whatever logic you want.
    nextTrack();
}

  const previousTrack = () => {
    // Implement your logic to play the previous track
    if(currentsong > 1){
      currentsong--;
      updateSong(currentsong);
      window.ChangeSong(currentsong);
      document.getElementById("songname").style.opacity = 0;
        document.getElementById("songname").style.transform = "translateX(-50px)";
        setTimeout(() => {
            document.getElementById("songname").style.transform = "translateX(50px)";
            document.getElementById("songname").innerText = songNames[currentsong-1];
            setTimeout(() => {
                document.getElementById("songname").style.opacity = 1;
                document.getElementById("songname").style.transform = "translateX(0px)";
                setTimeout(() => {
                  // audioRoofAnimationPlayer(true);
                  setIsPlaying(true);
                  document.querySelector("#playpause").src = pause;
                  window.audioRoofAnimationPlayer();
              }, 300);
            }, 300);
            
        }, 300);
    }
  };

  const adjustVolumeDown = () => {
    if(currentBar > 0){
      var volumeElements = document.querySelectorAll(".volume-container > img");
      currentBar--;
      updateBar(currentBar);
      window.ChangeVolume(currentBar);
      volumeElements[currentBar].classList.add("inactive-volume");
      // currentVolume -= 0.05;
      // sound.setVolume( currentVolume );
  }
  };
  const adjustVolumeUp = () => {
    if(currentBar < 10){
      var volumeElements = document.querySelectorAll(".volume-container > img");
      volumeElements[currentBar].classList.remove("inactive-volume");
      currentBar++;
      updateBar(currentBar);
      window.ChangeVolume(currentBar);
      // currentVolume += 0.05;
      // sound.setVolume( currentVolume );
  }
  };
  const CloseMusicContainer = () => {
    document.getElementById("audioContainer").style.opacity = 0;
    setTimeout(() => {
      // navigate('/mainscreen');
      document.getElementById("mainscreenContainer").style.display="block";
      // document.getElementById("steamScreenContainer").style.display="none";
      document.getElementById("audioContainer").style.display="none";
    }, 400);
    // setShouldRedirect(true);
    window.focusMain();
    window.ShowHotpoints();
   }
   useEffect(() => {
    
    
    // if (steamContainerRef.current) {
      // console.log("Element is now in the DOM!", steamContainerRef.current);
      setTimeout(() => {
        
      }, 100);
      
      document.getElementById("songname").innerText = songNames[currentsong-1];
      if(currentBar > 6){
        var volumeElements = document.querySelectorAll(".volume-container > img");
        for (let index = 5; index < (5+(currentBar-5)); index++) {
          volumeElements[index].classList.remove("inactive-volume");
        }
      }
      else{
        if(currentBar < 6){
          var volumeElements = document.querySelectorAll(".volume-container > img");
          for (let index = 5; index >= (5-(5-currentBar)); index--) {
            volumeElements[index].classList.add("inactive-volume");
          }
        }
      }
      
      
    // }
}, []);
useEffect(() => {
  if (shouldRedirect) {
    
    
  }
}, [shouldRedirect, navigate]);
  return (
    <div className="musicwrapper">
      <div className="musiccontainer" id="musicContainer">
        <div className="tracktext">
          <span className="Audio">AUDIO</span>
          <span id="songname" className="Trackname">MEDITATIVE</span>
        </div>
        <div className="Trackimage">
          <button onClick={previousTrack} className="prev">
            <img src={prev} alt=""/>
          </button>

          <button onClick={togglePlayPause} className="playpause">
            <img id="playpause" src={play} alt=""/>
          </button>

          <button onClick={nextTrack} className="next">
            <img src={next} alt=""/>
          </button>
        </div>

        <div className="volume">
          <button id="volume-down" onClick={() => adjustVolumeDown()}></button>

          <div className="volume-container">
            {/* I've kept your volume indicators as they were, but you might want to generate these dynamically based on the volume state. */}
            <img src={volbar} alt="" />
                <img src={volbar} alt=""/>
                <img src={volbar} alt=""/>
                <img src={volbar} alt=""/>
                <img src={volbar} alt=""/>
                <img src={volbar} alt=""/>
                <img src={volbar} alt="" className="inactive-volume"/>
                <img src={volbar} alt="" className="inactive-volume"/>
                <img src={volbar} alt="" className="inactive-volume"/>
                <img src={volbar} alt="" className="inactive-volume"/>
            {/* ... other volume indicators ... */}
          </div>

          <button id="volume-up" onClick={() => adjustVolumeUp()}></button>
        </div>
      </div>
        <button className="SteamButton" id="closeMusicContainer" onClick={CloseMusicContainer}>
          <img src={Cross} alt="" />
        </button>
    </div>
  );
};

export default MusicScreen;

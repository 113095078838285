import React, {useState, useEffect} from "react";
import "./Addons.css";
import { Link, useNavigate } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import addons1 from "../../Assets/addons1.webp";
import addons2 from "../../Assets/addons2.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";



const AddonsSpeaker = () => {
  
  const clients = [
    /*{
      img: addons1,
      review: "ADD-ONS",
      text: "Shower Seat",
      addonsparagraph1:
        "Take a load off during your steam sessions with a wall mounted, foldable shower seat. ",
    },*/
    {
      img: addons2,
      review: "SPEAKER SYSTEM",
      text: "ADD-ON",
      addonsparagraph1:
      "The Audio Speaker System comes</br> with the audio module and two</br> waterproof speakers. With its compact</br> design, the audio module features a</br> built-in high-powered amplifier, two</br> audio inputs and a wireless receiver.",
      addonsparagraph2:
        " ",
      pages: "VIEW Audio Module",
    }
    
  ];

//  "Skip through your playlist. Pause and<br/> play audio. Adjust bass, treble, balance<br/> and volume via the digital interface. ",
  
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
      setTimeout(() => {
        document.getElementById("addoncard").style.opacity = 1;
      }, 100);
  }, []); 
  useEffect(() => {
    if (shouldRedirect) {
      setTimeout(() => {
        // navigate('/mainscreen');
        document.getElementById("AddonsSpeakerContainer").style.display="none";
        document.getElementById("mainscreenContainer").style.display="block";
      }, 400);
      
    }
  }, [shouldRedirect, navigate]);
  const closeCardContainer = ()=>{
    document.getElementById("addoncard").style.opacity = 1;
    document.getElementById("AddonsSpeakerContainer").style.display="none";
    document.getElementById("mainscreenContainer").style.display="block";
    setShouldRedirect(true);
    console.log("close");
  }

  const handleAudioScreen = () => {
    document.getElementById("mainscreenContainer").style.display="none";
    document.getElementById("AddonsTwoCardHome").style.display="block";
    document.getElementById("AddonsSpeakerContainer").style.display="none";
    document.getElementById("addon1card").style.opacity = 1;
    // window.focusInstall();
  }
  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <div className="addonswrapper" id="addoncard">
      <Swiper
          cssMode={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          className="mySwiper"
        >
          {clients.reverse().map((clients, index) => {
            return (
              <SwiperSlide key={index}>
              <div className="Cardcontainer">
                <div className="Cardfixedcontainer">
                  <div className="Cardibutton">
                    <button className="btnlayers1">i</button>
                  </div>
                  <div className="Cardimagediv">
                    <img src={clients.img} alt="" />{" "}
                  </div>
                  <div className="Cardmaintext" dangerouslySetInnerHTML={createMarkup(clients.review)}></div>
                  <div className="Cardunilateral" dangerouslySetInnerHTML={createMarkup(clients.text)}></div>
                </div>

                <div className="Cardparacontainer">
                  <div className="Cardpara">
                    <p dangerouslySetInnerHTML={createMarkup(clients.addonsparagraph1)}></p>
                    <p className="steamheadpara3" dangerouslySetInnerHTML={createMarkup(clients.addonsparagraph2)}></p>
                  </div>
                </div>

                <div className="viewpages">
                  <div onClick={handleAudioScreen}>
                    <div className="moresettings">{clients.pages}</div>
                  </div>
                </div>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
       

     
    <div className="crossBtnContainer">
        <button className="SteamButton1" onClick={closeCardContainer}>
          <img src={Cross} alt="" />
        </button>
    </div>
    </div>
  );
};

export default AddonsSpeaker;

import React, { useState, useEffect} from "react";
import "./SteamHead.css";
import { Link, useNavigate } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import Steamhead from "../../Assets/steamheadimg.webp";
import Steamhead2 from "../../Assets/Steamheadimg2.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";

const SteamHead = () => {
  const clients = [
    {
      img: Steamhead,
      review: "STEAM HEAD",
      text: "Unilateral",
      steamheadparagraph1:
        "Unilateral steam head directs steam<br/> downward and features an essential oil<br/> reserve at the top. SteamScape™ Steam<br/> Packages include the control and steam<br/> head available in 7 unique finishes.",
      steamheadparagraph2:
        "Select from 2 different steam head<br/> options and see the steam effect<br/>in Steam Settings.",
      pages: "View steam settings",
    },
    {
      img: Steamhead2,
      review: "STEAM HEAD",
      text: "CONTEMPORARY SQUARE",
      steamheadparagraph1:
        "Contemporary Square steam head<br/>features 360<sup>o</sup> dispersion and an essential<br/>oil reserve on top. SteamScape™ Steam<br/> Packages include the control and steam<br/> head available in7 unique finishes.",
      steamheadparagraph2:
        "Select from 2 different steam head <br/> options and see the steam effect<br/>in Steam Settings.",
      pages: "View steam settings",
    },
  ];
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
      
    // if (steamContainerRef.current) {
      // console.log("Element is now in the DOM!", steamContainerRef.current);
      setTimeout(() => {
        document.getElementById("steamheadwrapper").style.opacity = 1;
      }, 100);
      
    // }
}, []);
useEffect(() => {
  if (shouldRedirect) {
    setTimeout(() => {
      // navigate('/mainscreen');
      document.getElementById("SteamHeadCardHome").style.display="none";
      document.getElementById("mainscreenContainer").style.display="block";
    }, 400);
    
  }
}, [shouldRedirect, navigate]);
  const closeCardContainer = ()=>{
    document.getElementById("steamheadwrapper").style.opacity = 1;
    // document.getElementById("SteamHeadCardHome").style.display="none";
    document.getElementById("SteamHeadCardHome").style.display="none";
    document.getElementById("mainscreenContainer").style.display="block";
    window.focusMain();
    // setShouldRedirect(true);
  }

  const handleViewSetting = () => {
    // document.getElementById("mainscreenContainer").style.display="none";
    document.getElementById("SteamHeadCardHome").style.display="none";
    document.getElementById("steamScreenContainer").style.display = "block";
    document.getElementById("steamScreenContainer").style.opacity = 1;
    window.focusSteamHead();
    // document.getElementById("SteamHeadCardHome").style.display="none";
  }
  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <div className="steamheadwrapper" id="steamheadwrapper">
            <Swiper
        cssMode={true}
        navigation={true}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="mySwiper"
      >
        {clients.map((clients, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="Cardcontainer">
              <div className='Cardfixedcontainer'>
                <div className="Cardibutton">
                  <button className="btnlayers1">i</button>
                </div>
                <div className="Cardimagediv">
                  <img src={clients.img} alt="" />{" "}
                </div>
                <div className="Cardmaintext" dangerouslySetInnerHTML={createMarkup(clients.review)}></div>
                <div className="Cardunilateral" dangerouslySetInnerHTML={createMarkup(clients.text)}></div>
                </div>

                <div className="Cardparacontainer">
                <div className='Cardpara '>
                  <p dangerouslySetInnerHTML={createMarkup(clients.steamheadparagraph1)}></p>
                  <p className="steamheadpara3" dangerouslySetInnerHTML={createMarkup(clients.steamheadparagraph2)}></p>
                </div>
                </div>
                <div className="viewpages">
                <div onClick={handleViewSetting}>
                <div className="moresettings">{clients.pages}</div>
                </div>
                </div>
                </div>
              
            </SwiperSlide>
          );
        })}
      </Swiper>

     
      <div className="crossBtnContainer">
        <button className="SteamButton1" onClick={closeCardContainer}>
          <img src={Cross} alt="" />
        </button>
    </div>
    </div>
  );
};

export default SteamHead;

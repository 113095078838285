import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./MainScreen.css";
import install from "../../Assets/Installation.svg";
import adjust from "../../Assets/Adjust.svg";
import Share from "../../Assets/Share.svg";
import Fire from "../../Assets/Firebtn.svg";
import Music from "../../Assets/Musicbtn.svg";
import Cross from "../../Assets/Cross.svg";
import steamOn from "../../Assets/steamOn.png";
import steamOff from "../../Assets/steamOff.png";
import Mainviewicon from "../../Assets/Mainviewicon.svg";

const MainScreen = ({ data, updateData, view, updateView }) => {

  
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [btnLayerExpanded, setBtnLayerExpanded] = useState(false);

  const handleChangeState = () => {
    updateData(true);
  };  
  // const [showAdjustButtons, setShowAdjustButtons] = useState(true);
  useEffect(() => {
    // This code will run right after the component is mounted to the DOM
    console.log('check popbool ' + data );
    isUp = false;
    if(!data){
      // setShowWelcomePopup(true);
      
    }

     

  }, []);


  useEffect(() => {

     if(!view){
      document.getElementById("ViewImage").src = Mainviewicon;
      document.getElementById("ViewText").innerText = "MAIN VIEW"
  
    }
    else{
      document.getElementById("ViewImage").src = install;
      document.getElementById("ViewText").innerText = "INSTALLATION"
    }
  }, [view]);
  const handleCloseWelcomePopup = () => {
    // localStorage.setItem('welcomePopupShown', 'true');
    // setShowWelcomePopup(false);
    
    // document.getElementById("pop").style.display = "none"
    // 
    document.getElementById('pop').style.opacity = 0;
    document.getElementById('smokeOverlayid').style.opacity = 0;
    document.getElementById('BlurOverlayid').style.opacity = 0;
    setTimeout(() => {
      // document.getElementById('pop').remove();
      handleChangeState();
      window.registerGyroHandler();
    }, 300);
  };

  const handleToggleBtnLayer = () => {
    setBtnLayerExpanded(!btnLayerExpanded);
  };


  const handleShareButtonClick = () => {
    if (navigator.share) {
      // Use navigator.share to share content
      navigator.share({
        url: "https://ar.deltafaucet.com/"
      })
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      // Handle the case where navigator.share is not available
      console.error('navigator.share is not supported in this environment');
    }
  };

  
 

  

  var isUp = false;
  // const adjBtns = document.querySelector(".adjustButtons");
  
  var isInstallView = false;
  // console.log(adjBtns);
  const move = () => {
    const steamBtn = document.querySelector(".steambutton");
  const musicBtn = document.querySelector(".musicbutton");
    if (isUp) {
      /*steamBtn.style.animation = "pushDownSteam 0.5s linear forwards";
      musicBtn.style.animation = "pushDownMusic 0.5s linear forwards";*/
      steamBtn.classList.remove('steamButtonActive')
      musicBtn.classList.remove('musicButtonActive')
      isUp = false;
    } else {
      /*steamBtn.style.animation = "bringUpSteam 0.5s linear forwards";
      musicBtn.style.animation = "bringUpMusic 0.5s linear forwards";*/
      steamBtn.classList.add('steamButtonActive')
      musicBtn.classList.add('musicButtonActive')
      isUp = true;
    }
  };

 const ChangeView = ()=>{
  console.log('changed view');
  if(view){
    // document.getElementById("ViewImage").src = Mainviewicon;
    // document.getElementById("ViewText").innerText = "MAIN VIEW"
    updateView(false);
    window.focusInstall();

  }
  else{
    // document.getElementById("ViewImage").src = install;
    // document.getElementById("ViewText").innerText = "INSTALLATION"
    updateView(true);
    window.focusMain();
  }
 }

 const handleMusicbutton = () => {
    window.focusSpeakers();
    window.HideHotpoints();
   document.getElementById("audioContainer").style.display="block";
   document.getElementById("mainscreenContainer").style.display="none";
   document.getElementById("audioContainer").style.opacity = 1;
 }

 const handleSteambutton = () => {
    window.focusSteamHead();
   document.getElementById("steamScreenContainer").style.display="block";
   document.getElementById("mainscreenContainer").style.display="none";
   document.getElementById("steamScreenContainer").style.opacity = 1;
   window.HideHotpoints();
   
 }

 

const handleToggle = () => {
  
  window.handleSteam();
};
document.addEventListener('visibilitychange', function() {
  window.location.reload();
});
 


  return (
    <div 
      className={`Wrapper ${!data ? "DimmedBackground" : ""}`}
      // onClick={handleScreenClick} // Close adjust buttons when clicking anywhere on the screen
    >
      {!data && (
        <div className="DimmedBackground" id="pop">
          <div className="WelcomePopup">
            <h2>Welcome</h2>
            <p>
            Position your phone vertically and move<br /> 
            your device from side to side to view the<br /> 
            entire experience.
              <br />
              <br />
              Tap on a hot point to view details about a specific product.
            </p>
            <button className="CloseButton" onClick={handleCloseWelcomePopup}>
              <img src={Cross} alt="" className="Cross" />
              {/* <span className="CloseIcon">&times;</span> */}
            </button>
          </div>
        </div>
      )}

    <button className="steamToggle1" onClick={handleToggle}>
            <img id="steamonoff1" src={Fire} alt="" />
          </button>

      

      <div
        className={`bottomContainer ${
          !(window.setPopBool) /*|| showAdjustButtons*/ ? "DimmedBottomBar" : ""
        }`}
      >
        <div className="btnContainer" onClick={ChangeView}>
            <div className="circleBtn">
              <img src={install} id="ViewImage" alt="" />
            </div>
          <span id="ViewText" className="btnText">INSTALLATION</span>
        </div>

        <div className="btnContainer" id="redBtn">
          <div className="circleBtn" id="REDbtn" onClick={move}>
            <img src={adjust} alt="" />
            <span className="btnText" id="redBtnText">
              Adjust
            </span>
            <div className="steambutton">
              {/* <Link to="/steamview"> */}
                <button className="hotbutton" onClick={handleSteambutton}>
                  <img src={Fire} alt="" />
                </button>
              {/* </Link> */}
            </div>

            <div className="musicbutton">
              {/* <Link to="/musicscreen"> */}
                <button className="musicButton" onClick={handleMusicbutton}>
                  <img src={Music} alt="" />
                </button>
              {/* </Link> */}
            </div>
          </div>
        </div>

        <div className="btnContainer">
          <div className="circleBtn" onClick={handleShareButtonClick}>
            <img src={Share} alt="" />
          </div>
          <span className="btnText">Share</span>
        </div>
      </div>

      {/* <div className="adjustButtons"> */}
      
      {/* </div> */}
    </div>
  );
};

export default MainScreen;

// import './libs/container-query-polyfill.modern';
import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import WelcomeScreen from "./Components/WelcomeScreen/WelcomeScreen"
import MainScreen from "./Components/MainScreen/MainScreen";
import InstallView from "./Components/IntsallView/InstallView";
import SteamView from "./Components/SteamView/SteamView";
import MusicScreen from "./Components/MusicScreen/MusicScreen";
import SteamHead from "./Components/SteamHead/SteamHead";
import Generator from "./Components/Generator/Generator";
import Addons from "./Components/Addons/Addons";
import Digitalinterface from "./Components/Digitalinterface/Digitalinterface";
import Addons2 from "./Components/Addons2/Addons2";
import Cards from "./Components/Cards/Cards";
import ThreeContainer from "./Components/Webgl/threejs";
import loadingSteamVideo from "./Assets/loading.mp4";
import Logo from "./Assets/Delta-Logo.png";
import Loader from "./Assets/Loader.gif";
import font from "./fonts/TradeGothicLTStd.otf"
import LandView from "./Assets/landView.png";
import QR from "./Assets/qr.png";
import AddonsSpeaker from "./Components/Addons/AddonsSpeaker";


const imageContext = require.context("./Assets", false, /\.(png|jpe?g|svg)$/);
const imageUrls = imageContext.keys().map(imageContext);

const fontContext = require.context("./fonts", false, /\.(otf)$/);
const fontUrls = fontContext.keys().map(fontContext);
console.log(fontUrls);

function App() {
  const [mainView, setMainView] = useState(true);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [loaderAssetsLoaded, setLoaderAssetsLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [popBool, setPopBool] = useState(false);
  const [currentSong, SetCurrentSong] = useState(1);
  const [currentBar, SetCurrentBar] = useState(6);
  const [steamBool, SetSteamBool] = useState(false);
  const [currentSteamHead, SetCurrentSteamHead] = useState(false);
  useEffect(() => {
    loadVideoAsset(loadingSteamVideo);
    loadImageAsset(Logo);
    loadFontAsset(font);

    function loadVideoAsset(url, onProgress) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer"; // or 'blob' depending on your needs

      /*xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      };*/

      xhr.onload = () => {
        // console.log(url, 'loaded');
        setVideoLoaded(true);
        // Handle loaded asset (e.g., append to the DOM, etc.)
        // You can create a Blob and then a URL from the blob if needed:
        // const blob = new Blob([xhr.response]);
        // const url = URL.createObjectURL(blob);
      };

      xhr.send();
    }

    function loadImageAsset(url, onProgress) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer"; // or 'blob' depending on your needs

      /*xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      };*/

      xhr.onload = () => {
        // console.log(url, 'loaded');
        setImageLoaded(true);
        // Handle loaded asset (e.g., append to the DOM, etc.)
        // You can create a Blob and then a URL from the blob if needed:
        // const blob = new Blob([xhr.response]);
        // const url = URL.createObjectURL(blob);
      };

      xhr.send();
    }

    function loadFontAsset(url) {
       const xhr = new XMLHttpRequest();
      //setFontLoaded(true);
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer"; // or 'blob' depending on your needs

      /*xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      };*/

      xhr.onload = () => {
        setFontLoaded(true);
      };

      xhr.send();
    }
  }, []);

  useEffect(() => {
    console.log(videoLoaded, imageLoaded, fontLoaded);
    if (videoLoaded && imageLoaded && fontLoaded) {
      setLoaderAssetsLoaded(true);
      document.getElementById("loader").style.display = "none";
    }
  }, [videoLoaded, imageLoaded, fontLoaded]);

  useEffect(() => {
    console.log("starting main loading");

    const handleImageLoad = () => {
      setLoadedImagesCount((prevCount) => prevCount + 1);
    };

    if (loaderAssetsLoaded) {
      imageUrls.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = handleImageLoad;
      });
    }
  }, [loaderAssetsLoaded]);

  useEffect(() => {
    console.log(loadedImagesCount);
    if (document.getElementById("bar") != null) {
      document.getElementById("bar").style.backgroundSize =
        loadedImagesCount + "% 100%";
    }

    if (loadedImagesCount === imageUrls.length) {
      setAssetsLoaded(true);
      
      console.log("finished UI");
    }
  }, [loadedImagesCount, imageUrls.length]);

  /*
useEffect(() => {
  if (loadedImagesCount === imageUrls.length) {
    setAssetsLoaded(true);
    
  }
  console.log(loadedImagesCount);
 
}, [loadedImagesCount, imageUrls.length]);

const handleImageLoad = () => {
    
  setLoadedImagesCount(prevCount => prevCount + 1);
  
  
};


useEffect(() => {
  
  imageUrls.forEach((src) => {
    const img = new Image();
    img.src = src;
    img.onload = handleImageLoad
  });
}, []);*/



  return (
    <>
    <Router>
      <div id="desktopBlock">
        <img className="qr" src={QR} />
        <div className="centeredtext">Please use mobile for experience</div>
      </div>
      <div id="landscapeBlock">
          <img className="landscape" src={LandView}/>
      </div>
      <div id="loader">
        <img src={Loader} />
      </div>

      <div className="App">
        <div className="webglcontainer">
          <ThreeContainer loadModel={assetsLoaded} />
          <div id="smokeOverlayid" className="smokeOverlay"></div>
          <div id="BlurOverlayid" className="BlurOverlay"></div>
        </div>
        <div id="loadingContainer">{loaderAssetsLoaded ? <LoadingScreen /> : null}</div>
        <div id="welComeContainer">
          <WelcomeScreen />
        </div>
        <div id="mainscreenContainer">
          <MainScreen
            data={popBool}
            updateData={setPopBool}
            view={mainView}
            updateView={setMainView}
          />
        </div>
        {/* <div id="installContainer">
          <InstallView />
        </div> */}
        <div id="audioContainer">
          <MusicScreen
            song={currentSong}
            updateSong={SetCurrentSong}
            bar={currentBar}
            updateBar={SetCurrentBar}
          />
        </div>
        <div id="steamScreenContainer">
          <SteamView
            steam={steamBool}
            UpdateSteam={SetSteamBool}
            steamhead1={currentSteamHead}
            UpdateSteamHead1={SetCurrentSteamHead}
          />
        </div>
        <div id="AddonsCardHome">
          <Addons />
        </div>
        <div id="SteamHeadCardHome">
          <SteamHead />
        </div>
        <div id="GeneratorCardHome">
          <Generator />
        </div>
        <div id="AddonsTwoCardHome">
          <Addons2 />
        </div>
        <div id="DigitalInterfaceCardHome">
          <Digitalinterface />
        </div>
        <div id="AddonsSpeakerContainer">
          <AddonsSpeaker />
        </div>

        {/* <div>{loaderAssetsLoaded ? <LoadingScreen /> : null}</div>
        <div>
          <WelcomeScreen />
        </div>
        <div>
          <MainScreen
            data={popBool}
            updateData={setPopBool}
            view={mainView}
            updateView={setMainView}
          />
        </div>
        <div>
          <InstallView />
        </div>
        <div>
          <SteamView
            steam={steamBool}
            UpdateSteam={SetSteamBool}
            steamhead1={currentSteamHead}
            UpdateSteamHead1={SetCurrentSteamHead}
          />
        </div>
        <div>
          <MusicScreen
            song={currentSong}
            updateSong={SetCurrentSong}
            bar={currentBar}
            updateBar={SetCurrentBar}
          />
        </div>
        <div>
          <SteamHead />
        </div>
        <div>
          <Generator />
        </div>
        <div>
          <Addons />
        </div>
        <div>
          <Addons2 />
        </div>
        <div>
          <Cards />
        </div>
        <div>
          <Digitalinterface />
        </div> */}

        {/* <Routes>
        <Route
            path="/loading"
            element={loaderAssetsLoaded ? <LoadingScreen /> : null}
          />
           
          <Route path="/welcome" element={<WelcomeScreen />} />

          
          <Route path="/mainscreen" element={<MainScreen data={popBool} updateData={setPopBool} view={mainView} updateView={setMainView}/>} />

          
          <Route path="/installview" element={<InstallView />} />

          
          <Route path="/steamview" element={<SteamView steam={steamBool} UpdateSteam={SetSteamBool} steamhead1={currentSteamHead} UpdateSteamHead1={SetCurrentSteamHead}/>} />
          
          
          <Route path="/musicscreen" element={<MusicScreen song={currentSong} updateSong={SetCurrentSong} bar={currentBar} updateBar={SetCurrentBar} />} />

          
          <Route path="/steamheadscreen" element={<SteamHead />} />

          
          <Route path="/generator" element={<Generator/>} />

         
          <Route path="/addons" element={<Addons/>} />

          
          <Route path="/addons2" element={<Addons2/>} />

          
          <Route path="/cards" element={<Cards/>} />

          
          <Route path="/Digitalinterface" element={<Digitalinterface/>} />
          <Route
            path="/"
            element={loaderAssetsLoaded ? <Navigate to="/loading" /> : null}
          />
        </Routes> */}
      </div>
    </Router>
    </>
  );
}

export default App;

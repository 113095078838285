import React from "react";
import "./LoadingScreen.css";
import loadingVideo from "../../Assets/loading.mp4";

const LoadingScreen = () => {
  return (
    <div className="Main-Screen">
      <video className="Smoke" src={loadingVideo} muted autoPlay playsInline loop></video>
      {/* <div className="smokeOverlay"></div> */}
      <div className="Logo">
      </div>
      <div className="Loading">
          <h1>Loading...</h1>
          <div className="barContainer">
            <div id="bar" className="Bar"></div>
            <div className="BarFill"></div>
          </div>
          
      </div>
      
    </div>
  );
};

export default LoadingScreen;

import React, {useState, useEffect} from "react";
import "./Addons2.css";
import { Link, useNavigate } from "react-router-dom";
import Cross from "../../Assets/Cross.svg";
import addons4 from "../../Assets/addons4.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";

const Addons2 = () => {
  const clients = [
    {
      img: addons4,
      review: "AUDIO MODULE",
      text: "ADD 0N",
      addonsparagraph1:
        "The Audio Speaker System comes with the</br> audio module and two waterproof speakers.</br> With its compact design, the audio module</br> features a built-in high-powered amplifier,</br> two audio inputs and a wireless receiver.",
      addonsparagraph2:
        " ",
      pages: "VIEW SPEAKERS",
    },
  ];
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("addon1card").style.opacity = 1;
    }, 100);
}, []); 
useEffect(() => {
  if (shouldRedirect) {
    setTimeout(() => {
      // navigate('/mainscreen');
      document.getElementById("AddonsTwoCardHome").style.display="none";
      document.getElementById("mainscreenContainer").style.display="block";

    }, 400);
    
  }
}, [shouldRedirect, navigate]);
const closeCardContainer = ()=>{
  document.getElementById("addon1card").style.opacity = 1;
  document.getElementById("AddonsTwoCardHome").style.display="none";
  document.getElementById("mainscreenContainer").style.display="block";
  setShouldRedirect(true);
  console.log("close");
}

const handleAddOnsTwoClick = () => {
  document.getElementById("mainscreenContainer").style.display="none";
  document.getElementById("AddonsSpeakerContainer").style.display="block";
  document.getElementById("AddonsTwoCardHome").style.display="none";
  // window.focusSpeakers();
}
function createMarkup(content) {
  return { __html: content };
}
  return (
    <div className="addons2wrapper" id="addon1card">
      <Swiper
          cssMode={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          loop={false}
          pagination={{ clickable: true }}
          className="mySwiper"
        >
      {clients.map((clients, index) => {
        return (
          <SwiperSlide key={index+1}>
          <div className="Cardcontainer" key={index}>
            <div className="Cardfixedcontainer">
              <div className="Cardibutton">
                <button className="btnlayers1">i</button>
              </div>
              <div className="Cardimagediv">
                <img src={clients.img} alt="" />{" "}
              </div>
              <div className="Cardmaintext">{clients.review}</div>
              <div className="Cardunilateral">{clients.text}</div>
            </div>

            {/* <div className="Cardparacontainer"> */}
            <div className="Cardpara">
              <p dangerouslySetInnerHTML={createMarkup(clients.addonsparagraph1)}></p>
              <p className="steamheadpara3" dangerouslySetInnerHTML={createMarkup(clients.addonsparagraph2)}></p>
            </div>
            {/* </div> */}

            <div className="viewpages">
              <div onClick={handleAddOnsTwoClick}>
                <div className="moresettings">{clients.pages}</div>
              </div>
            </div>
          </div>
          </SwiperSlide>
        );
      })}
      </Swiper>
      <div className="crossBtnContainer">
          <button className="SteamButton1" onClick={closeCardContainer}>
            <img src={Cross} alt="" />
          </button>
      </div>
    </div>
  );
};

export default Addons2;
